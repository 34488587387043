import { useEffect, useMemo, useState } from 'react';
import { Button, Dialog, Divider, IconButton, Paper, Typography, Box } from '@mui/material';
import StickyTable from 'components/tables/react-table/StickyTable';
import MainCard from 'components/MainCard';
import { Add, Edit, Trash } from 'iconsax-react';
import { useNavigate } from 'react-router-dom';
import { PopupTransition } from 'components/@extended/Transitions';
import { useDeleteFlagMutation, useLazyListFlagQuery } from './flag-api-slice';
import { openSnackbar } from 'store/reducers/snackbar';
import Loader from 'components/Loader';
import { dispatch } from 'store';

const FlagList = () => {
  const navigate = useNavigate();
  const [id, setId] = useState<number>();
  const [confirm, setConfirm] = useState<boolean>(false);
  const [flags, { data: list, isLoading }] = useLazyListFlagQuery();
  const [deleteItem, { isLoading: loading }] = useDeleteFlagMutation();

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: '',
        className: 'cell-left',
        Cell: ({ row }: any) => {
          return <>{row.index + 1}</>;
        }
      },
      {
        Header: 'Location',
        accessor: 'location',
        className: 'cell-center'
      },
      {
        Header: 'Telegram Flag',
        accessor: 'telegramFlag',
        className: 'cell-center'
      },
      {
        Header: 'Flag',
        accessor: '',
        className: 'cell-center',
        Cell: ({ row }: any) => {
          return <img src={row.original.flagLink} width="24" alt="flag" />;
        }
      },
      {
        Header: 'Flag Link',
        accessor: 'flagLink',
        className: 'cell-center'
      },
      {
        Header: 'Action',
        accessor: 'id',
        className: 'cell-center',
        Cell: ({ value }: { value: number }) => (
          <>
            <IconButton
              color="primary"
              size="medium"
              onClick={() => {
                navigate(`/flag/edit/${value}`);
              }}
            >
              <Edit variant="TwoTone" />
            </IconButton>

            <IconButton
              color="primary"
              size="medium"
              onClick={() => {
                setId(value);
                setConfirm(true);
              }}
            >
              <Trash variant="TwoTone" color="#Be2525" />
            </IconButton>
          </>
        )
      }
    ],
    [navigate]
  );

  const getList = async () => {
    try {
      await flags();
    } catch (error) {}
  };

  const handleDelete = async () => {
    try {
      await deleteItem(id as number).unwrap();
      dispatch(
        openSnackbar({
          open: true,
          message: 'success',
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
      await getList();
      setConfirm(false);
    } catch (error) {
      dispatch(
        openSnackbar({
          open: true,
          message: 'error',
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
      setConfirm(false);
    }
  };

  useEffect(() => {
    getList();
    return () => {};
  }, []);

  if (isLoading) return <Loader />;

  return (
    <MainCard content={false}>
      {list !== undefined && (
        <>
          <StickyTable
            title="Flag List"
            columns={columns as any}
            data={(list as any) || []}
            striped={true}
            headerTable={
              <>
                <Button
                  fullWidth
                  variant="contained"
                  startIcon={<Add />}
                  size="large"
                  sx={{ maxWidth: '240px', mr: '12px' }}
                  onClick={() => {
                    navigate('/flag/add');
                  }}
                >
                  Add New Flag
                </Button>
              </>
            }
          />
        </>
      )}

      <Dialog
        maxWidth="xs"
        TransitionComponent={PopupTransition}
        fullWidth
        onClose={() => setConfirm(false)}
        open={confirm}
        sx={{ '& .MuiDialog-paper': { p: 0, bgcolor: 'secondary.lighter' } }}
      >
        <Paper sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: '1.25rem' }}>
          <Box sx={{ width: '100%' }}>
            <Typography variant="h5">confirmation</Typography>
          </Box>
          <Box sx={{ width: '100%', py: '0.75rem' }}>
            <Divider />
          </Box>
          <Typography variant="h5" py={'0.75rem'}>
            Are you sure to delete this item?
          </Typography>
          <Button
            type="button"
            variant="contained"
            disableElevation
            disabled={loading}
            sx={{ maxWidth: 120, mb: 'auto' }}
            onClick={handleDelete}
          >
            ok
          </Button>
        </Paper>
      </Dialog>
    </MainCard>
  );
};

export default FlagList;

import { useNavigate, useParams } from 'react-router-dom';
import { FormHelperText, Grid, InputLabel, OutlinedInput, Stack, Button } from '@mui/material';
import { joiResolver } from '@hookform/resolvers/joi';
import { Controller, useForm } from 'react-hook-form';
import Joi from 'joi';
import MainCard from 'components/MainCard';
import { openSnackbar } from 'store/reducers/snackbar';
import { dispatch } from 'store';
import { useEffect } from 'react';
import { useEditFlagMutation, useLazyGetFlagQuery } from '../flag-api-slice';
import { IEditFlag } from 'models/flag';

const EditFlag = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [postData, { error, isError, isLoading }] = useEditFlagMutation();

  const [getFlag, { data: flag }] = useLazyGetFlagQuery();

  const GetFlag = async () => {
    try {
      if (id !== undefined) {
        await getFlag(+id).unwrap();
      }
    } catch (error) {}
  };

  const schema = Joi.object({
    id: Joi.any().optional(),
    location: Joi.string().required().messages({
      'string.empty': 'required'
    }),
    telegramFlag: Joi.string().required().messages({
      'string.empty': 'required'
    }),
    flagLink: Joi.string().required().messages({
      'string.empty': 'required'
    })
  });

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = useForm<IEditFlag>({
    resolver: joiResolver(schema)
  });

  useEffect(() => {
    GetFlag();
    return () => {};
  }, [id]);

  useEffect(() => {
    if (flag) {
      setValue('id', flag?.id);
      setValue('location', flag?.location);
      setValue('telegramFlag', flag?.telegramFlag);
      setValue('flagLink', flag?.flagLink);
    }
    return () => {};
  }, [setValue, flag]);

  const onSubmit = async () => {
    try {
      await postData(getValues()).unwrap();

      dispatch(
        openSnackbar({
          open: true,
          message: 'success',
          variant: 'alert',
          alert: {
            color: 'success'
          },
          close: false
        })
      );
      navigate('/flag/list');
    } catch (err) {}
  };

  return (
    <MainCard title="Edit Flag">
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3.5}>
          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="location">Location</InputLabel>
              <Controller
                name="location"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <OutlinedInput
                    id="location"
                    type="text"
                    placeholder="Enter Your Location"
                    fullWidth
                    error={Boolean(errors?.location)}
                    {...field}
                  />
                )}
              />
              {errors?.location && <FormHelperText error>{errors?.location?.message}</FormHelperText>}
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="telegramFlag">Telegram Flag</InputLabel>
              <Controller
                name="telegramFlag"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <OutlinedInput
                    id="telegramFlag"
                    type="text"
                    placeholder="Enter Your Telegram Flag"
                    fullWidth
                    error={Boolean(errors?.telegramFlag)}
                    {...field}
                  />
                )}
              />
              {errors?.telegramFlag && <FormHelperText error>{errors?.telegramFlag?.message}</FormHelperText>}
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="flagLink">Flag Link</InputLabel>
              <Controller
                name="flagLink"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <OutlinedInput
                    id="flagLink"
                    type="text"
                    placeholder="Enter Your Flag Link"
                    fullWidth
                    error={Boolean(errors?.flagLink)}
                    {...field}
                  />
                )}
              />
              {errors?.flagLink && <FormHelperText error>{errors?.flagLink?.message}</FormHelperText>}
            </Stack>
          </Grid>

          {isError && (
            <Grid item xs={12} sm={6}>
              <FormHelperText error>
                {
                  // @ts-ignore
                  error?.data?.message
                }
              </FormHelperText>
            </Grid>
          )}

          <Grid item xs={12}>
            <Stack direction="row" justifyContent="flex-start">
              <Button disableElevation disabled={isLoading} type="submit" variant="contained" color="primary" sx={{ minWidth: 120 }}>
                submit
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
};

export default EditFlag;
